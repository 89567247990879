import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`404 Not found`}</h1>
    <h3>{`You seem to be lost...`}</h3>
    <p>{`Maybe you should use the `}<a parentName="p" {...{
        "href": "https://trailguide.net"
      }}>{`Trailguide App`}</a>{` to navigate?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      